import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import JobCardList from '@components/JobCardList';
import Button from '@components/core/Button';
import Select from '@components/core/Select';
import Text from '@components/core/Text';
import useInfiniteLoad from '@hooks/useInfiniteLoad';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import styles from './current-openings.module.scss';

const query = graphql`
    query CurrentOpeningsQuery {
        openingsQuery: allMarkdownRemark(
            filter: {
                frontmatter: { status: { eq: "open" } }
                fields: { slug: { regex: "/jobs/" } }
            }
            sort: { order: DESC, fields: frontmatter___published }
        ) {
            nodes {
                frontmatter {
                    title
                    location
                    discipline
                }
                fields {
                    slug
                }
            }
        }
    }
`;

const getOpenings = (openings, selectedFilter) => {
    if (selectedFilter) {
        return openings.filter(
            opening => opening.discipline === selectedFilter
        );
    }

    return openings;
};

const ITEMS_PER_PAGE = 6;
const INITIAL_ITEMS = 6;
const MOBILE_ITEMS_PER_PAGE = 3;
const MOBILE_INITIAL_ITEMS = 3;

const CurrentOpenings = ({ data }) => {
    const { openingsQuery } = useStaticQuery(query);
    const { md } = useBreakpoint();
    const openings = openingsQuery.nodes.reduce((acc, opening) => {
        acc.push({
            title: opening.frontmatter.title,
            location: opening.frontmatter.location,
            path: opening.fields.slug,
            discipline: opening.frontmatter.discipline,
        });
        return acc;
    }, []);

    const filters = [
        ...new Set(
            openingsQuery.nodes.reduce((acc, opening) => {
                acc.push(opening.frontmatter.discipline);
                return acc;
            }, [])
        ),
    ];

    const [selectedFilter, setSelectedFilter] = useState('');
    const [filteredOpenings, setFilteredOpenings] = useState(openings);
    const [visibleItems, nextPage, isLastPage] = useInfiniteLoad(
        filteredOpenings,
        md ? INITIAL_ITEMS : MOBILE_INITIAL_ITEMS,
        md ? ITEMS_PER_PAGE : MOBILE_ITEMS_PER_PAGE
    );

    useEffect(() => {
        setFilteredOpenings(getOpenings(openings, selectedFilter));
    }, [selectedFilter]);

    return (
        <Section className={styles.container}>
            <Grid>
                <Row>
                    <Column md="7">
                        <SectionHeader
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
                <div className={styles.filterSpacing}>
                    <Row>
                        <Column>
                            <div className={styles.filterContainer}>
                                <Text
                                    as="p"
                                    size="paragraphSm"
                                    className={styles.filterLabel}
                                >
                                    {' '}
                                    Explore by
                                </Text>
                                <Select
                                    className={styles.select}
                                    value={selectedFilter}
                                    aria-label="Explore by"
                                    onChange={event => {
                                        setSelectedFilter(event.target.value);
                                    }}
                                >
                                    <option value="">All categories</option>
                                    {filters.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </Column>
                    </Row>
                </div>
                <div className={styles.content}>
                    <JobCardList openings={visibleItems} />
                </div>
                {!isLastPage && (
                    <Button variant="ghost" size="block" onClick={nextPage}>
                        <Text as="span" size="paragraphSm">
                            Load More
                        </Text>
                    </Button>
                )}
            </Grid>
        </Section>
    );
};

CurrentOpenings.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
};

export default CurrentOpenings;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CaretDownIcon from '@components/Icons/caret-down.svg';
import styles from './select.module.scss';

const Select = ({ className, ...rest }) => {
    const classNames = cx([className, styles.wrapper]);
    return (
        <div className={classNames}>
            <select className={styles.select} {...rest} />
            <CaretDownIcon className={styles.icon} />
        </div>
    );
};

Select.propTypes = {
    className: PropTypes.string,
};

Select.defaultProps = {
    className: '',
};

export default Select;

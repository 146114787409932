import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import Select from '../Select';
import FormHelperText from '../../FormHelperText/FormHelperText';
import styles from './select-formik.module.scss';

const SelectFormik = ({ className, ...props }) => {
    const [field, meta] = useField(props);
    const hasError = meta.error && styles.selectError;
    return (
        <>
            <div className={styles.container}>
                <Select className={hasError} {...field} {...props} />
            </div>
            {meta.touched && (
                <FormHelperText type="error" message={meta.error} />
            )}
        </>
    );
};

SelectFormik.propTypes = {
    className: PropTypes.string,
};

SelectFormik.defaultProps = {
    className: 'selectError',
};

export default SelectFormik;

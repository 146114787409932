import React from 'react';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Section from '@components/core/Section';
import PageHeader from '@components/PageHeader';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql } from 'gatsby';
import WhyZemoga from '@sections/Careers/WhyZemoga/WhyZemoga';
import CurrentOpenings from '@sections/Careers/CurrentOpenings';
import CareerPath from '@sections/Careers/CarrerPath';
import ApplyForm from '@sections/Careers/ApplyForm';
import OurPeople from '@sections/Careers/OurPeople';
import GlassdoorTestimony from '@sections/Careers/GlassdoorTestimony';
import ParallaxSection from '@components/core/ParallaxSection';
import styles from './careers.module.scss';

const CareersPage = ({
    data: {
        markdownRemark: {
            frontmatter: {
                title,
                seo,
                pageHeader,
                whyZemoga,
                careerPath,
                currentOpenings,
                signUp,
                ourPeople,
            },
        },
    },
}) => {
    const breakpoints = useBreakpoint();
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout disableFooterParallax={false} navBarExternalBg>
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Section className={styles.careersHeaderSection}>
                <PageHeader
                    containerClass={styles.containerClass}
                    infoHeader={pageHeader}
                />
            </Section>
            <ParallaxSection as="div" disabled={!breakpoints.md}>
                <WhyZemoga data={whyZemoga} />
                <CareerPath data={careerPath} />
            </ParallaxSection>
            <CurrentOpenings data={currentOpenings} />
            <ApplyForm data={signUp} />
            <OurPeople className="hide" data={ourPeople} />
            <GlassdoorTestimony />
        </Layout>
    );
};

CareersPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query CareersPage {
        markdownRemark(fields: { slug: { eq: "/pages/careers/" } }) {
            frontmatter {
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                pageHeader {
                    title
                    headerDescription: description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    previewUrl {
                        src: publicURL
                    }
                    videoUrl
                    videoCms {
                        src: publicURL
                    }
                }
                whyZemoga {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
                careerPath {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
                currentOpenings {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
                signUp {
                    title
                    summary
                }
                ourPeople {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
            }
        }
    }
`;

export default CareersPage;

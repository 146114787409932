import { useState, useEffect } from 'react';

const useInfiniteLoad = (items, initialItems = 1, itemsIncrement = 1) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleItems, setVisibleItems] = useState(
        items.slice(0, initialItems)
    );
    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        setVisibleItems(items.slice(0, initialItems));
        setCurrentPage(1);
    }, [items, initialItems, itemsIncrement]);

    useEffect(() => {
        const itemsToShow = items.slice(
            0,
            initialItems + currentPage * itemsIncrement - itemsIncrement
        );
        setVisibleItems(itemsToShow);
    }, [currentPage]);

    return [visibleItems, nextPage, visibleItems.length === items.length];
};

export default useInfiniteLoad;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '@components/core/Section';
import Grid, { Column, Row } from '@components/core/Grid';
import SectionTitle from '@components/SectionTitle';
import { formatData } from '../../../utils/utils';
import CareersForm from '../CareersForm/CareersForm';
import styles from './apply-form.module.scss';

const ApplyForm = () => {
    const sectionQuery = graphql`
        query ApplyFormSections {
            disciplines: allMarkdownRemark(
                filter: { fields: { slug: { regex: "/disciplines/" } } }
                sort: { order: DESC, fields: frontmatter___published }
            ) {
                Discipline: edges {
                    node {
                        frontmatter {
                            area
                            discipline
                        }
                    }
                }
            }

            singUp: markdownRemark(
                fields: { slug: { eq: "/pages/careers/" } }
            ) {
                frontmatter {
                    signUp {
                        title
                        summary
                    }
                }
            }
        }
    `;

    const {
        disciplines: { Discipline },
        singUp: {
            frontmatter: {
                signUp: { title, summary },
            },
        },
    } = useStaticQuery(sectionQuery);

    const dataSelectTag = formatData(Discipline);
    return (
        <Section className={styles.section}>
            <Grid>
                <Row>
                    <Column md="5" className={styles.columnLeft}>
                        <SectionTitle title={title} summary={summary} />
                    </Column>
                    <Column md="6" offsetMd="1">
                        <CareersForm dataSelectTag={dataSelectTag} />
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

export default ApplyForm;

import React from 'react';
import PropTypes from 'prop-types';
import useCareerPaths from '@hooks/useCareerPaths';
import ArticleCard from '@components/Cards/Article';
import CarouselWithPadding from '@components/Carousels/CarouselWithPadding';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import styles from './career-path.module.scss';

const CareerPath = ({ data }) => {
    const careerPathData = useCareerPaths();
    const breakpoint = useBreakpoint();
    const cardLayoutBehavior = () => {
        if (breakpoint.md) {
            return 'horizontal';
        }
        return 'vertical';
    };

    return (
        <Section
            variant="dark"
            dataTheme="dark"
            className={styles.careerPathSection}
        >
            <Grid>
                <Row>
                    <Column md="8">
                        <SectionHeader
                            variant="dark"
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
            </Grid>
            <CarouselWithPadding
                infinite
                isFullScreen
                sliderClassName={styles.carousel}
            >
                {careerPathData.map(info => {
                    /* TODO: Fix this error - info.data.path is undefined */
                    const article = {
                        slug: info.data.path || '',
                        image: info.data.image,
                        topic: info.data.category,
                        title: info.data.title,
                        published: info.data.published,
                        overview: info.data.overview,
                    };
                    return (
                        <ArticleCard
                            key={article.published}
                            article={article}
                            sizeTitle="h3"
                            colorTitle="white"
                            layoutDistribution={cardLayoutBehavior()}
                            colorOverview="lightGrey"
                            classCardWrapper={styles.cardWrapper}
                            classImageWrapper={styles.imageWrapper}
                            classInfo={styles.info}
                            isFullScreen
                        />
                    );
                })}
            </CarouselWithPadding>
        </Section>
    );
};

CareerPath.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
    }).isRequired,
};

export default CareerPath;

import { useStaticQuery, graphql } from 'gatsby';

export const formatData = nodesData => {
    if (!nodesData || !Array.isArray(nodesData)) {
        return nodesData;
    }

    return nodesData;
};

export default () => {
    const data = useStaticQuery(graphql`
        query GetCareersPath {
            result: allMarkdownRemark(
                limit: 5
                filter: { fields: { slug: { regex: "/career-path/" } } }
                sort: { order: ASC, fields: [frontmatter___order] }
            ) {
                nodes {
                    data: frontmatter {
                        title
                        published(formatString: "YYYY-MM-DD HH:mm:ss")
                        overview
                        image {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 243, maxHeight: 318) {
                                    ...GatsbyImageSharpFluid
                                    presentationWidth
                                }
                            }
                        }
                    }
                }
                totalCount
            }
        }
    `);

    const { nodes } = data.result;

    return nodes && formatData(nodes);
};

import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from 'gatsby';
import CarouselWithPadding from '@components/Carousels/CarouselWithPadding';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import WhyZemogaCards from '@components/Cards/WhyZemoga';
import styles from './why-zemoga.module.scss';
import { formatData } from '../../../utils/utils';

const MultipleCards = ({ cards }) =>
    cards.map(({ published, image, text_content: text, title }) => {
        return (
            <WhyZemogaCards
                key={`why-zemoga-cards-${published}`}
                image={image}
                text={text}
                title={title}
                sizeTitle="h5"
                colorTitle="white"
            />
        );
    });

const sectionQuery = graphql`
    query WhyZemogaSectionQuery {
        whyZemoga: allMarkdownRemark(
            filter: { fields: { slug: { regex: "/why-zemoga/" } } }
            limit: 4
            sort: { order: DESC, fields: frontmatter___published }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        text_content
                        published
                        image {
                            childImageSharp {
                                fluid(maxWidth: 600) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const WhyZemoga = ({ data }) => {
    const breakpoints = useBreakpoint();
    const { whyZemoga = {} } = useStaticQuery(sectionQuery);
    const cardsInfo = formatData(whyZemoga.edges);
    return (
        <Section
            className={styles.whyZemogaSection}
            variant="dark"
            dataTheme="dark"
        >
            <Grid className={styles.headerGrid}>
                <Row>
                    <Column md="8">
                        <SectionHeader
                            variant="dark"
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
                {breakpoints.md && (
                    <Row>
                        <Column md="12" className={styles.colSlider}>
                            <div className={styles.section}>
                                <MultipleCards cards={cardsInfo} />
                            </div>
                        </Column>
                    </Row>
                )}
            </Grid>
            {!breakpoints.md && (
                <CarouselWithPadding infinite>
                    {cardsInfo.map(
                        ({
                            published,
                            path,
                            image,
                            category,
                            title: articleTitle,
                            text_content: text,
                        }) => {
                            return (
                                <WhyZemogaCards
                                    key={`why-zemoga-cards-${published}`}
                                    slug={path}
                                    image={image}
                                    text={text}
                                    topic={category}
                                    sizeTitle="h5"
                                    title={articleTitle}
                                    colorTitle="white"
                                />
                            );
                        }
                    )}
                </CarouselWithPadding>
            )}
        </Section>
    );
};

WhyZemoga.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
    }).isRequired,
};

export default WhyZemoga;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from '@components/core/Grid';
import JobCard from '@components/Cards/Job';
import styles from './job-card-list.module.scss';

const JobCardList = ({ openings }) => {
    return (
        <Row className={styles.row}>
            {openings.map(({ title, location, path }) => {
                return (
                    <Column className={styles.column} sm="4" md="4" key={path}>
                        <JobCard
                            title={title}
                            location={location}
                            path={path}
                        />
                    </Column>
                );
            })}
        </Row>
    );
};

JobCardList.propTypes = {
    openings: PropTypes.arrayOf(PropTypes.shape(JobCard.propTypes)).isRequired,
};

export default JobCardList;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import VideoCard from '@components/Cards/Video';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import cx from 'classnames';
import styles from './our-people.module.scss';
import { formatArrayData } from '../../../utils/utils';

const GetOurPeople = graphql`
    query GetOurPeople {
        ourPeople: allMarkdownRemark(
            limit: 2
            sort: { fields: [frontmatter___published], order: DESC }
            filter: { fields: { slug: { regex: "/our-people/" } } }
        ) {
            nodes {
                frontmatter {
                    titleCard: title
                    published
                    descCard: description
                    video {
                        src: publicURL
                    }
                    preview {
                        src: publicURL
                    }
                }
            }
        }
    }
`;

const OurPeople = ({ data, className }) => {
    const { ourPeople = {} } = useStaticQuery(GetOurPeople);
    const info = formatArrayData(ourPeople.nodes);
    return (
        <Section className={cx(styles.ourPeopleSection, className)}>
            <Grid>
                <Row>
                    <Column md="8">
                        <SectionHeader
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column md="12">
                        <div className={styles.videoSection}>
                            {info &&
                                info.map(
                                    ({
                                        titleCard,
                                        published,
                                        descCard,
                                        video,
                                        preview,
                                    }) =>
                                        video &&
                                        preview && (
                                            <VideoCard
                                                key={`our-people-video-${published}`}
                                                title={`“${titleCard}“`}
                                                sizeTitle="h5"
                                                description={descCard}
                                                videoSrc={video.src}
                                                videoSrcPreview={preview.src}
                                            />
                                        )
                                )}
                        </div>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

OurPeople.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

OurPeople.defaultProps = {
    className: '',
};

export default OurPeople;

import React from 'react';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Quotes from '@components/Quotes/Quotes';
import ParallaxSection from '@components/core/ParallaxSection';
import styles from './glassdoor-testimony.module.scss';

const formatData = QuotesData => {
    return QuotesData.map(quotes => ({
        author: quotes.node.frontmatter.author,
        testimony: quotes.node.frontmatter.testimony,
        published: quotes.node.frontmatter.published,
        image: quotes.node.frontmatter.image.childImageSharp.fluid,
    }));
};

const GlassdoorTestimony = () => {
    const {
        filterQuotes: { QuotesTestimony },
    } = useStaticQuery(graphql`
        query GetQuotesQuery {
            filterQuotes: allMarkdownRemark(
                filter: { fields: { slug: { glob: "/quotes/*" } } }
            ) {
                QuotesTestimony: edges {
                    node {
                        frontmatter {
                            author
                            testimony
                            published
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 140) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const breakpoints = useBreakpoint();
    const QuotesGlassdoorTestimony = formatData(QuotesTestimony);
    const settings = {
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 20000,
        pauseOnHover: false,
    };

    return (
        <ParallaxSection
            variant="dark"
            dataTheme="dark"
            className={styles.section}
            disabled={!breakpoints.md}
            bottomDisabled
        >
            <Slider {...settings}>
                {QuotesGlassdoorTestimony.map(
                    ({ author, testimony, image, published }) => (
                        <Quotes
                            key={`slider-glassdoor-${published}`}
                            author={author}
                            testimony={testimony}
                            image={image}
                        />
                    )
                )}
            </Slider>
        </ParallaxSection>
    );
};

export default GlassdoorTestimony;

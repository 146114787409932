import React from 'react';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import styles from './section-title.module.scss';

const SectionTitle = ({ title, summary }) => (
    <>
        <Text className={styles.titleContainer} size="h5" as="h5" color="light">
            {title}
        </Text>

        <Text as="p" size="paragraphMd" color="light">
            {summary}
        </Text>
    </>
);

SectionTitle.propTypes = {
    title: PropTypes.string,
    summary: PropTypes.string,
};

SectionTitle.defaultProps = {
    title: '',
    summary: '',
};

export default SectionTitle;

import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Image from '@components/core/Image';
import styles from './why-zemoga-card.module.scss';

const WhyZemogaCard = ({ title, sizeTitle, colorTitle, image, text }) => {
    const { childImageSharp = {} } = image || {};
    return (
        <div className={styles.card}>
            <div>
                <Image
                    className={styles.imageContainer}
                    fixed={childImageSharp.fixed}
                    fluid={childImageSharp.fluid}
                />
            </div>
            <div className={styles.info}>
                <Text size={sizeTitle} color={colorTitle}>
                    {title}
                </Text>
                {text && (
                    <Text as="p" size="paragraphSm" className={styles.cardText}>
                        {text}
                    </Text>
                )}
            </div>
        </div>
    );
};

WhyZemogaCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
            fixed: PropTypes.shape({
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
            }),
        }),
    }).isRequired,
    text: PropTypes.string.isRequired,
    sizeTitle: PropTypes.string,
    colorTitle: PropTypes.string,
};

WhyZemogaCard.defaultProps = {
    colorTitle: 'black',
    sizeTitle: 'paragraphSm',
};

export default WhyZemogaCard;

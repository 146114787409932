import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@components/core/Button';
import Text from '@components/core/Text';
import { InputFormik, InputFileFormik } from '@components/core/Input';
import { SelectFormik } from '@components/core/Select';
import { useStaticQuery, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useCaptchaValidation from '@hooks/useCaptchaValidation';
import FormHelperText from '@components/core/FormHelperText/FormHelperText';
import styles from './careers-form.module.scss';

const CareersForm = ({ dataSelectTag }) => {
    const { md } = useBreakpoint();
    const sizeButton = md ? 'fluid' : 'block';
    const [formFeedback, setFormFeedback] = useState();

    const {
        markdownRemark: {
            frontmatter: { privacyPolicy },
        },
    } = useStaticQuery(graphql`
        query {
            markdownRemark(
                frontmatter: { listTitle: { eq: "PrivacyPolicy" } }
            ) {
                frontmatter {
                    privacyPolicy {
                        privacyPolicyFile {
                            publicURL
                        }
                        title
                    }
                }
            }
        }
    `);

    const privacyPolicyToUse = privacyPolicy.find(
        policy => policy.title === 'Política de Privacidad'
    );

    const classContainerButton = formFeedback
        ? styles.containerSpaced
        : styles.container;

    const setFormError = () => {
        setFormFeedback({
            type: 'error',
            message: 'Please try again later',
        });
    };
    const createBlobFile = fileToUpload => {
        const binary = atob(fileToUpload.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i += 1) {
            array.push(binary.charCodeAt(i));
        }
        const blobData = new Blob([new Uint8Array(array)], {
            type: '',
        });
        return blobData;
    };

    const sendEmail = formData => {
        return fetch(
            `${process.env.GATSBY_LAMBDA_HUBSPOT_CONNECTOR_API}/email`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            }
        );
    };

    function validateFilesSizeLimit(files) {
        if (!files) {
            return true;
        }
        const invalidFiles = Array.from(files).filter(
            file => file.size / 1024 / 1024 > 3
        );

        if (invalidFiles.length > 0) {
            return false;
        }
        return true;
    }

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    discipline: '',
                    cv: '',
                    cvData: '',
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email('Invalid email address')
                        .required('An email is required'),
                    discipline: Yup.string().required(
                        'A discipline is required'
                    ),
                    cv: Yup.mixed()
                        .required('A cv attached is required')
                        .test(
                            'size_limit',
                            'The document must have a maximum size of 3MB',
                            validateFilesSizeLimit
                        ),
                })}
                onSubmit={async (values, { setStatus }) => {
                    setStatus('submitting');
                    setFormFeedback({
                        type: 'success',
                        message: 'Please wait, we’re submitting your info.',
                    });
                    const uploadFile = ({ token }) => {
                        const fileName = `${Date.now()}${values.cv[0].name}`;
                        fetch(
                            `${process.env.GATSBY_LAMBDA_HUBSPOT_CONNECTOR_API}/upload?fileName=${fileName}&gToken=${token}`
                        )
                            .then(data => data.json())
                            .then(async response => {
                                const { fileUploadURL, status } = response;
                                if (status === 'error') {
                                    setFormError();
                                } else {
                                    const blobData = createBlobFile(
                                        values.cvData
                                    );
                                    const uploadResponse = await fetch(
                                        fileUploadURL,
                                        {
                                            method: 'PUT',
                                            body: blobData,
                                        }
                                    );

                                    if (uploadResponse.status === 500) {
                                        setFormError();
                                    }

                                    const emailData = {
                                        email: values.email,
                                        discipline: values.discipline,
                                        cvLink: uploadResponse.url.split(
                                            '?'
                                        )[0],
                                    };

                                    const emailResponse = await sendEmail(
                                        emailData
                                    );

                                    if (emailResponse.status === 500) {
                                        setFormError();
                                    }

                                    setStatus('sent');
                                }
                            });
                    };
                    const token = await useCaptchaValidation();
                    uploadFile({ token });
                }}
            >
                {({ handleSubmit, status }) => {
                    return (
                        <>
                            {status === 'sent' ? (
                                <div className={styles.feedbackContainer}>
                                    <Text
                                        as="p"
                                        size="paragraphMd"
                                        className={styles.thanksTitle}
                                    >
                                        Thank you for contacting us!
                                    </Text>

                                    <Text as="span" size="paragraphSm">
                                        We’re pretty responsive, like our
                                        design.
                                        <br /> So be on the lookout for a ping
                                        back from us.
                                    </Text>
                                </div>
                            ) : (
                                <Form onSubmit={handleSubmit}>
                                    <InputFormik
                                        name="email"
                                        type="email"
                                        placeholder="What's your email?"
                                    />

                                    <SelectFormik name="discipline">
                                        <option value="">
                                            Please choose a discipline
                                        </option>
                                        {dataSelectTag.map(element => (
                                            <option
                                                key={element.discipline}
                                                value={element.discipline}
                                            >
                                                {element.discipline}
                                            </option>
                                        ))}
                                    </SelectFormik>
                                    <InputFileFormik
                                        label="Now, attach your CV"
                                        name="cv"
                                        type="file"
                                    />
                                    <Text
                                        className={styles.policy}
                                        as="p"
                                        size="paragraphSm"
                                    >
                                        By clicking on “Send” I accept the{' '}
                                        <a
                                            href={
                                                privacyPolicyToUse
                                                    .privacyPolicyFile.publicURL
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className={styles.underline}
                                        >
                                            Privacy Policy
                                        </a>
                                    </Text>
                                    <div className={classContainerButton}>
                                        {formFeedback && (
                                            <FormHelperText
                                                type={formFeedback.type}
                                                message={formFeedback.message}
                                                className="formFeedback"
                                            />
                                        )}
                                        <Button
                                            disabled={status === 'submitting'}
                                            className={styles.buttonSize}
                                            size={sizeButton}
                                            variant="dark"
                                            type="submit"
                                        >
                                            <Text as="span" size="paragraphSm">
                                                Send
                                            </Text>
                                            <img
                                                height="1"
                                                width="1"
                                                style={{ display: 'none' }}
                                                alt=""
                                                src="https://px.ads.linkedin.com/collect/?pid=2733474&conversionId=3871234&fmt=gif"
                                            />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

CareersForm.propTypes = {
    dataSelectTag: PropTypes.arrayOf(
        PropTypes.shape({
            area: PropTypes.string.isRequired,
            discipline: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default CareersForm;

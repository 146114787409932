import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Text from '@components/core/Text';
import AnchorToSection from '@components/core/AnchorToSection';
import styles from './job-card.module.scss';

const JobCard = ({ title, location, path }) => {
    return (
        <Link to={path} className={styles.container}>
            <Text as="h5" size="h5" className={styles.title}>
                {title}
            </Text>
            <Text as="p" size="paragraphMd" className={styles.location}>
                {location}
            </Text>
            <AnchorToSection className={styles.cta} sizeIcon="4">
                Apply now
            </AnchorToSection>
        </Link>
    );
};

JobCard.propTypes = {
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};

export default JobCard;

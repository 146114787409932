import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Video from '@components/Video';
import PlaybackButton from '@components/core/PlaybackButton';
import styles from './video-card.module.scss';

const VideoCard = ({
    title,
    sizeTitle,
    colorTitle,
    description,
    videoSrc,
    videoSrcPreview,
}) => {
    const [isDisabledOnMouse, updateDisabledOnMouseState] = useState(false);
    const [shouldPlay, updatePlayState] = useState(false);
    const [shouldPlayFull, updatePlayFullState] = useState(false);

    const handleEventsVideo = (nameEvent, e) => {
        if (nameEvent !== 'ended') e.stopPropagation();
        if (!isDisabledOnMouse && nameEvent === 'over') {
            updatePlayState(true);
        } else if (!isDisabledOnMouse && nameEvent === 'leave') {
            updatePlayState(false);
        } else if (nameEvent === 'click') {
            updatePlayFullState(!shouldPlayFull);
            updateDisabledOnMouseState(true);
        } else if (nameEvent === 'ended') {
            updatePlayFullState(false);
        }
    };

    return (
        <div className={styles.container}>
            <div
                className={styles.video}
                onClick={e => {
                    handleEventsVideo('click', e);
                }}
                onMouseOver={e => {
                    handleEventsVideo('over', e);
                }}
                onMouseLeave={e => {
                    handleEventsVideo('leave', e);
                }}
                tabIndex={0}
                role="button"
                onFocus={() => {}}
                onKeyDown={() => {}}
            >
                <Video
                    src={!isDisabledOnMouse ? videoSrcPreview : videoSrc}
                    controls={false}
                    playing={!isDisabledOnMouse ? shouldPlay : shouldPlayFull}
                    muted={!isDisabledOnMouse ? shouldPlay : !shouldPlayFull}
                    loop={!isDisabledOnMouse}
                    onEnded={e => {
                        handleEventsVideo('ended', e);
                    }}
                />
                <div className={styles.containerButton}>
                    <PlaybackButton isPlaying={shouldPlayFull} />
                </div>
            </div>

            <div className={styles.info}>
                <Text
                    size={sizeTitle}
                    color={colorTitle}
                    className={styles.title}
                >
                    {title}
                </Text>
                <Text as="p" size="paragraphMd" className={styles.description}>
                    {description}
                </Text>
            </div>
        </div>
    );
};

VideoCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    videoSrc: PropTypes.string.isRequired,
    videoSrcPreview: PropTypes.string.isRequired,
    sizeTitle: PropTypes.string,
    colorTitle: PropTypes.string,
};

VideoCard.defaultProps = {
    colorTitle: 'black',
    sizeTitle: 'paragraphSm',
};

export default VideoCard;

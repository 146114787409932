const formatData = nodesData => {
    if (!nodesData || !Array.isArray(nodesData)) {
        return nodesData;
    }

    return nodesData.map(({ node }) => {
        const { frontmatter, fields } = node;
        return {
            ...frontmatter,
            ...fields,
        };
    });
};

const formatArrayData = arrayData => {
    if (!arrayData || !Array.isArray(arrayData)) {
        return arrayData;
    }

    return arrayData.map(({ frontmatter, fields }) => {
        return {
            ...frontmatter,
            ...fields,
        };
    });
};

const replaceSlug = (completeURL, newSlug) => {
    if (!newSlug) {
        return undefined;
    }

    const slug = completeURL.split('/');
    slug.splice(-2, 1, newSlug);
    return slug.join('/');
};

const makeCustomSlugArticle = (slug, customSlug) => {
    return customSlug ? `/insights/blog/${customSlug}/` : slug;
};

// TODO - find a way to share common utils between node and gatsby front end.
module.exports = {
    formatData,
    formatArrayData,
    replaceSlug,
    makeCustomSlugArticle,
};
